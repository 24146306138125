@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply text-primary-white bg-primary-black;
    padding: 0;
    margin: 0;
    overscroll-behavior-y: none;
}

@layer components {
    :root {
        --header-desktop-height: 148px;
        --header-mobile-height: 108px;
    }
}

@layer utilities {
    .text-balance {
        text-wrap: balance;
    }

    .user-select-none * {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}

* {
    box-sizing: border-box;
}
